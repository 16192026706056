import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layout'

const Page = ({
  data: {
    pagesJson: { author, description, image, metaTags, slug, title, components }
  },
  location
}) => <Layout location={location} title={title} description={description} image={image} author={author} metaTags={metaTags} slug={slug} components={components} />

export const query = graphql`
  query ($id: String) {
    pagesJson(id: { eq: $id }) {
      author
      components {
        template
        logo
        links_color
      }
      description
      image
      # metaTags
      slug
      title
      ...Banner
      ...Careers
      ...CareersContactForm
      ...ContactForm
      ...ContactInfo
      ...ContentTabs
      ...Cta
      ...Hero
      ...Infographic
      ...List
      ...RichText
      ...Team
      ...Testimonials
      ...TextColumns
      ...TextTabs
    }
  }
`

export default Page
